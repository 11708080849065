@font-face {
    font-family: "Campton";
    font-weight: bold;
    font-style: normal;
    src: url("fonts/CamptonBold.otf");
}

@font-face {
    font-family: "Campton";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/CamptonBook.otf");
}

html,
body {
    height: 100vh;
    padding: 0;
    margin: 0;
    font-family: "Campton";
    font-size: 12px;
}

#mainBackground {
    position: fixed;
    opacity: 0.2;
}

hr {
    border-top-color: #999999;
}

p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

a {
    margin-left: 5px;
    margin-right: 5px;
}

#floatingShowHideButton {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    z-index: 5000;
}

@media (max-width: 991px) {
    #floatingShowHideButton {
        display: block;
    }
}

.clickable {
    cursor: pointer;
}

#dashboard {
    padding-bottom: 150px;
}

.todoDone {
    background-color: #00bc8c66;
}

.subTodo {
    background-color: #44444466;
}

.lifestyleSection .plusIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 2em;
    font-size: 3em;
    opacity: 0.6;
    text-align: right;
    z-index: -10;
    padding-right: 20px;
    visibility: hidden;
}

.lifestyleSection:hover .plusIcon {
    visibility: visible;
}

.form-control {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #dddddd;
}

.form-control:focus,
.form-control:active {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid white;
    color: white;
    text-shadow: 0px 1px 3px black;
}

.hidingEverything .hidingElement {
    filter: blur(4px);
}

.noteContent {
    min-height: 30vh;
}

.tab-content {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid grey;
}

.btn {
    cursor: pointer;
}

.form-label {
    margin-right: 5px;
}

.card-header {
    cursor: pointer;
}

#floatingShowHideButton {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}